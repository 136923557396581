export default class Endpoints {
  static token = '/token/';

  static logout = '/logout/';

  static refreshToken = '/token/refresh/';

  static recoveryPassword = '/auth/users/reset_password/';

  static resetPassword = '/auth/users/reset_password_confirm/';

  static users = '/users';

  static analysts = '/analysts';

  static evaluationClubs = '/evaluation_clubs';

  static organizations = '/organizations';

  static teams = '/teams';

  static clubAthletes = id => `${Endpoints.teams}/${id}/athletes/`;

  static agencyAthletes = id => `${Endpoints.agencies}/${id}/athletes/`;

  static athletes = '/athletes';

  static customAthletes = '/custom-athletes';

  static athleteMigration = '/athlete_migration';

  static countries = '/countries';

  static monitoring = '/lists';

  static notifications = '/notifications';

  static athleteEvaluations = id => `${Endpoints.athletes}/${id}/evaluations/`;

  static newEvaluation = athleteId => `${Endpoints.athletes}/${athleteId}/evaluations/`;

  static saveEvaluation = (athleteId, id) => `${Endpoints.athletes}/${athleteId}/evaluations/${id}/`;

  static search = '/search';

  static competitions = '/competitions';

  static reports = '/reports';

  static evaluations = '/evaluations';

  static evaluationsStats = '/evaluations_stats';

  static dashboardTeams = '/teams_dashboard';

  static dashboardTransferMarket = '/transfer_market_dashboard';

  static dashboardMarketAnalysis = '/market_analysis_dashboard';

  static dashboardChat = '/chat_dashboard';

  static transferWindows = '/transfer_windows';

  static transferWindowsCategories = '/transfer_window_categories';

  static transferWindowsStatuses = '/transfer_window_statuses';

  static agencies = '/agencies';

  static agencyRequests = '/agency_requests';

  static scripts = '/scripts';

  static banners = '/banners';

  static athleteVisits = '/athlete_visits';

  static activities = '/activities';

  static projects = '/projects';

  static networks = '/networks';

  static transferMarket = '/transfer_market';

  static desiredTransferMarket = '/desired_transfermarket_athlete';

  static desiredAthletesOffered = '/desired_athletes_offered';

  // chats

  static negotiations = '/negotiations';

  static negotiationMessages = '/messagesnegotiations';

  static teamChat = '/teamchat';

  static teamChatMessages = '/teamchat_messages';

  static orgChat = '/orgchat';

  static orgChatMessages = '/orgchat_messages';

  static athleteChat = '/athletechat';

  static athleteChatMessages = '/athletechat_messages';

  static shadowTeams = id => `${Endpoints.projects}/${id}/shadow_teams`;

  static evaluationsFields = '/evaluations_fields';

  static firstAccess = '/first-access';

  static userCode = '/user-code';

  static videoAccess = '/videos_access';
}
