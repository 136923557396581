import React, { useState, useEffect } from 'react';
import { Dialog, Card, Checkbox, Input, Spinner, IconButton } from 'components/template';
import { MdClear, MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
//import "../../../translations/i18n"
import styles from './DialogContent.module.scss';
import { FaSearch } from 'react-icons/fa';
import { AiFillPlusCircle, AiFillDelete } from 'react-icons/ai';
import Variables from 'variables';

function DialogContent({ loggedUser, setIsOpen, list, getList, createList, editList, deleteList, athlete, addMonitor, deleteMonitor, onAthleteListChange }) {
  const { t } = useTranslation();

  const [monitoringLists, setMonitoringLists] = useState(list.data.filter(item => item.name !== '_DEFAULT_'));
  const [displaingList, setDisplainglist] = useState(monitoringLists);
  const [checkedLists, setCheckedLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [listCreation, setListCreation] = useState(false);
  const [savingId, setSavingId] = useState(null);
  const [editListName, setEditListName] = useState('');
  const [listDeletion, setListDeletion] = useState({
    isOpen: false,
    item: null,
  });
  const [listEdit, setListEdit] = useState({
    isOpen: false,
    item: null,
  });

  const [showValidationMessage, setShowValidationMessage] = useState(false);

  useEffect(() => {
    if (!listCreation) {
      setNewListName('');
      setTimeout(function () {
        setIsOpen(true);
      }, 200);
    }
  }, [listCreation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!listDeletion.isOpen) {
      setTimeout(function () {
        setIsOpen(true);
      }, 200);
    }
  }, [listDeletion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!(list?.loading ?? true) && (displaingList ?? []).length === 0) {
      getList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (list.data.length !== 0) {
      const defaultList = list.data.find(item => item.name === '_DEFAULT_');
      const withoutDefault = list.data.filter(item => item.name !== '_DEFAULT_');
      setMonitoringLists(withoutDefault);
      setDisplainglist(withoutDefault);

      const athleteLists = athlete?.lists ?? [];
      setCheckedLists(athleteLists.filter(id => id !== defaultList?.id));
      onAthleteListChange();
    }
  }, [list.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheck = async id => {
    setSavingId(id);
    if (checkedLists.some(list => list === id)) {
      //delete
      const deleted = await deleteMonitor(athlete.id, id);
      if (deleted) {
        athlete.lists = athlete.lists.filter(list => list !== id);
        onAthleteListChange();
        setCheckedLists(checkedLists.filter(list => list !== id));
      }
    } else {
      //add
      const added = await addMonitor(athlete.id, id);
      if (added) {
        athlete.lists.push(id);
        onAthleteListChange();
        setCheckedLists([...checkedLists, id]);
      }      
    }
    setSavingId(null);
  }

  function handleInput(value) {
    setSearchVal(value);

    if (value !== '') {
      setDisplainglist(
        monitoringLists.filter(item => {
          return item.name.toLowerCase().search(value.toLowerCase()) !== -1;
        })
      );
    } else {
      setDisplainglist(monitoringLists);
    }
  }

  function mountDisplay(list) {        
    return list.map(item => {
      const isMyItem = loggedUser.id === item.owner || loggedUser.plan.mensageria;      
      return (
        <div className={styles.listCheckboxs}>                                    
          <div
            onClick={() =>{
              if (isMyItem) {
                setListDeletion({
                  isOpen: true,
                  item,
                })
              }
            }}
            className={isMyItem ? styles.divDelete : styles.divDisable }
          >
            <AiFillDelete color={isMyItem ? Variables.lightPurple2 : Variables.gray5} />
          </div>                  
          <div
            onClick={() =>{
              if(isMyItem) {
                setEditListName(item.name);          
                setListEdit({
                  isOpen: true,
                  item,
                })
              }
            }}
            className={isMyItem ? styles.divDelete : styles.divDisable }
          >
            <MdEdit color={isMyItem ? Variables.lightPurple2 : Variables.gray5}/>
          </div>
          <Spinner 
            width="16" 
            height="16" 
            className={styles.spinnerSaving} 
            style={{ display: savingId === item.id ? 'block' : 'none' }} 
          />
          <Checkbox
            onChange={() => {
              if (savingId == null) {
                handleCheck(item.id);
              }
            }}
            checked={checkedLists.some(id => id === item.id)}
            className={savingId === item.id ? styles.checkboxItemSaving : styles.checkboxItem}
            key={item.id}
          />
          <small>{item.name}</small>
        </div>
      );
    })
  }  

  const onCreateList = () => {
    if (monitoringLists.some(list => list.name === newListName)) {
      setShowValidationMessage(true);
    } else {
      setShowValidationMessage(false);
      createList({ name: newListName });
      setListCreation(false);
    }
  }

  let content = null;

  if (list.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner width="50" height="50" />
      </div>
    );
  } else if (list.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(list.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        {/* Lists */}
        <div className={styles.monitoringTitle}>
          <span>{t('dialog_content.include')}</span> <MdClear onClick={() => setIsOpen(false)} />
        </div>
        <div className={styles.contentContainer}>
          <Input
            value={searchVal}
            placeholder={t('rest.box34')}
            onChange={event => handleInput(event.target.value)}
            containerClassName={styles.inputContainer}
            rounded
            icon={<FaSearch />}
            iconPosition="right"
          />

          {/* Checkboxes */}
          {displaingList && <>
              <h3>Minhas listas</h3>
              {mountDisplay(displaingList.filter(item => loggedUser.id === item.owner))}
              <h3>Listas da minha organização</h3>
              {mountDisplay(displaingList.filter(item => loggedUser.id !== item.owner))}
            </>
          }
        </div>
        <div className={styles.buttonsContainer}>
          <IconButton
            className={styles.add_button}
            text={t('rest.box35')}
            icon={<AiFillPlusCircle />}
            onClick={() => setListCreation(true)}
          >
            {t('dialog_content.new_create')}
          </IconButton>
        </div>
      </>
    );
  }

  return (
    <>
      <Card className={`${styles.card} ${styles.largeMenu}`} softShadow>
        {content}
      </Card>
      <Dialog
        isOpen={listCreation}
        onClose={() => setListCreation(false)}
        mainTitle={t('dialog_content.list')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => setListCreation(false)}
        btnRight={t('dialog_content.create')}
        btnRightClick={onCreateList}
        className={{
          body: styles.bodyDialog,
        }}
      >
        <span>{t('agencies.name')}:</span>
        <Input
          value={newListName}
          onChange={e => setNewListName(e.target.value)}
          placeholder={t('dialog_content.text12')}
          inputClassName={styles.inputCreate}
        />
        {showValidationMessage && <h2 className={styles.validationMessage}>{t('dialog_content.warn')}</h2>}
      </Dialog>
      <Dialog
        isOpen={listDeletion.isOpen}
        onClose={() => {
          setListDeletion({ isOpen: false, item: null });
        }}
        mainTitle={t('proj.deleteTitle')}
        btnLeft={t('dialog_content.cancel')}
        btnLeftClick={() => {
          setListDeletion({ isOpen: false, item: null });
        }}
        btnRight={t('buttons.excluir')}
        btnRightClick={() => {
          deleteList(listDeletion.item.id);
          setListDeletion({ isOpen: false, item: null });
          setTimeout(function () {
            setIsOpen(true);
          }, 200);
        }}
      >
        <span style={{ marginBottom: '10px', marginLeft: '5px' }}>
          {t('dialog_content.deleteMessage')} "{listDeletion?.item?.name ?? ''}"?
        </span>
      </Dialog>
      <Dialog
        isOpen={listEdit.isOpen}
        onClose={() => {
          setListEdit({ isOpen: false, item: null });
        }}
        mainTitle={'Editar Lista'}
        btnLeft={'Cancelar'}
        btnLeftClick={() => {
          setListEdit({ isOpen: false, item: null });
        }}
        btnRight={'Salvar'}
        btnRightClick={() => {
          editList(listEdit.item.id, editListName);
          setListEdit({ isOpen: false, item: null });
          setTimeout(function () {
            setIsOpen(true);
          }, 200);
        }}
      >
        <Input value={editListName} onChange={e => setEditListName(e.target.value)} />
      </Dialog>
    </>
  );
}

export default DialogContent;
