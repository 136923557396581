export const TRANSLATIONS_BR = {
  pagina_inicial: {
    homepage_1: 'Últimas movimentações',
    homepage_2: 'Informações do sistema',
    homepage_3: 'Janelas Abertas',
    homepage_4: 'Últimos visualizados',
    homepage_5: 'Ver mais',
    homepage_5_2: 'Ver mais movimentações',
    homepage_6: 'Ver todas as janelas',
    homepage_7: 'Tudo',
    homepage_8: 'Registros no BID',
    homepage_9: 'Atividades',
    homepage_10: 'Informação',
    homepage_11: 'Atualizado em',
    homepage_15: 'Janelas de transferências',
    homepage_16: 'Meu Feed',
    homepage_17: 'Seu feed com tudo o que de mais importante aconteceu desde o seu último acesso.',
    homepage_18: 'Não há janelas abertas no momento.',
    homepage_19: 'Ver menos',
    homepage_20: 'Negociações',
    open: 'Abertas',
    monitored: 'Monitoradas',
    selectToMonitor: 'Selecionar Janelas para monitorar',
    youDontMonitor: 'Você ainda não monitora Janelas de transferências'
  },

  buttons: {
    excluir: 'Excluir',
    cancel: 'Cancelar',
    save: 'Salvar',
    edit: 'Editar',
  },

  nav_drawer: {
    meuPerfil: 'Meu Perfil',
    organizacao: 'Organização',
    verPerfilMeuClube: 'Ver perfil do meu clube',
    verPerfilMinhaAgencia: 'Ver perfil da minha Agência',
    feed: 'Meu Feed',
    buscaAtletas: 'Busca de Atletas',
    clubes: 'Clubes',
    meuClube: 'Meu Clube',
    competicoesBase: 'Competições',
    analiseMercado: 'Análise de Mercado',
    mercadoTransferencias: 'Mercado de Transferências',
    redeProfissionais: 'Rede de Profissionais',
    agencias: 'Agências',
    comprar: 'COMPRAR',
    dashboard: 'Dashboard',
  },

  athlete_card: {
    without_agency: 'Não informado',
    idade: ' anos',
    meses: 'meses e',
    days: 'dias',
    text1: 'Contratos na CBF',
    text2: 'Data do BID',
    text3: 'Início',
    text4: 'Término',
    text5: 'Estado',
    text6: 'Valor(M €)',
    text7: 'Total movimentado:',
    text8: 'Tipo de Negociação: Todos',
    text9: 'Status do Anúncio: Todos',
    edit_pic: 'Editar Foto',
    nickname: 'Apelido',
    fullname: 'Nome Completo',
    nation: 'Nacionalidade',
    birth: 'Nascimento',
    height: 'Altura',
    weight: 'Peso',
    pos: 'Posição',
    foot: 'Pé preferencial',
    edit_athl: 'Editar Atleta',
    add_athl: 'Adicionar Atleta',
    add_athl_msg1: 'O atleta adicionado será visível apenas para a sua organização!',
    add_athl_msg2: 'Fique tranquilo para usá-lo no Análise de Mercado.',
    filter1: 'Clube: Todos',
    anotacoes: 'anotações',
    buyValuation: 'Comprar Valuation',
    zero_cost: 'Custo Zero',
    selectTeam: 'Selecionar Clube',
    error: {
      nationalities: 'Preencha pelo menos uma nacionalidade!',
      name: 'O nome do atleta não deve conter caracteres inválidos.',
      birthdate: 'O atleta deve possuir ao menos 7 anos para ser cadastrado.',
      is_retired: 'Este atleta não está mais ativo no Footlink.',
    },
  },

  athlete_list: {
    box1: 'Jogador',
    box2: 'Clube',
    curret_club: 'Clube no momento',
    box3: 'Idade',
    box4: 'Posição',
    box5: 'Nac.',
    box6: 'Fim do Contrato',
    exist: 'Existe ',
    existplural: 'Existem ',
    founded: 'Encontramos ',
    tip: 'Gerar relatório',
    text1: ' atleta',
    text1plural: ' atletas',
    text2: ' atleta com os critérios da sua pesquisa',
    text2plural: ' atletas com os critérios da sua pesquisa',
    noAthletes: 'Nenhum atleta foi encontrado com os critérios da sua pesquisa.',
    noAthletesReport: 'Você deve selecionar ao menos um atleta para gerar o relatório.',
    ageInComp: 'Idade na competição', 
  },

  month: {
    // Janeiro,
    // Fevereiro,
    // Março,
    // Abril,
    // Maio,
    // Junho,
    // Julho: "",
    // Agosto: "",
    // Setembro: "",
    // Outubro: "",
    // Novembro: "",
    // Dezembro: "December",
  },

  contact_card: {
    type: 'Tipo',
    category: 'Categoria',
    role: 'Cargo',
    phone: 'Telefone',
    error: 'Ocorreu um problema, favor revise seus dados',
  },

  table: {
    origin: 'Origem',
    destiny: 'Destino',
    age_moment: 'Idade no momento',
    price: 'Valor',
    text: 'Ver avaliação',
    text2: 'Ver',
    data: 'Data',
    analist: 'Analista',
    clube: 'Clube no momento',
    model: 'Modelo de avaliação',
    general: 'Avaliação Geral',
    criadoEm: 'Criado Em',
    atualizadoEm: 'Atualizado Em',
    atleta: 'Atleta',
    idade: 'Idade',
    posicao: 'Posição',
    nasc: 'Nasc.',
    contrato: 'Contrato até',
  },

  dialog_content: {
    list: 'Nova Lista',
    warn: 'Já existe uma lista com o nome inserido',
    create: 'Criar Lista',
    cancel: 'Cancelar',
    noCancel: 'Não, cancelar',
    include: 'Inclua o atleta em listas',
    save: 'Salvar',
    proceed: 'Prosseguir',
    yesProceed: 'Sim, prosseguir',
    new_create: 'Criar nova lista',
    deleteMessage: 'Tem certeza que deseja excluir a lista ',
    text1: 'Marcar como agenciado',
    text2: 'Sua agência representa este atleta?\nMarque-o como um de seus atletas.',
    text3: 'Marcar como meu agenciado',
    text4: 'Denunciar',
    text5: 'Este não é o agente deste atleta?\nAvise-nos para que possamos corrigir a informação.',
    text6: 'Enviar denúncia',
    text7: 'Desmarcar como agenciado',
    text8: 'Não é meu agenciado',
    text9: 'Sua agência ',
    text10: ' representa este atleta? Desmarque-o como um de seus atletas.',
    text11: 'NÃO',
    text12: 'Nome da nova lista',
  },

  report: {
    gene_in: 'Gerado em ',
    project: 'Projeto ',
    relat: 'Relatório de Atleta',
    gene_for: 'Gerado por ',
    pdf: 'Gerando PDF...',
    ex_relat: 'Exportar Relatório',
    relat_error: 'Ocorreu algum problema ao gerar o time-sombra para o relatório, favor tente novamente',
    success: 'Seu relatório foi configurado com sucesso!',
    generate: 'No botão abaixo você poderá tanto imprimir quanto gerar um PDF.',
    export_pdf: ' Exportar para PDF',
    print_out: 'Imprimir/Exportar relatório',
    reportData: 'Dados do Relatório',
    reportName: 'Nome do relatório',
    position: 'Posição',
    athlete: 'Atleta',
    positions: 'Posições',
    appAccessAuthError: 'Você está tentando exportar um relatório com um usuário diferente do logado na WEB, favor saia da sua conta na WEB e tente novamente.',
  },

  slider_menu: {
    in: 'Entre',
    de: '(De):',
    ate: '(Até):',
    clean: 'Limpar',
    e: 'e',
    seas: 'Temporada',
    todas: 'Todas',
    season_all: 'Temporada: Todas',
    age_all: 'Idade: Todos',
    value_all: 'Valor: Todos',
    team_all: 'Equipe: Todas',
    competition_all: 'Competição: Todas',
    group_by: 'Agrupar por: ',
    criterion: 'Nenhum critério',
    value_sale: 'Valor para Venda (em €)',
    value_salary: 'Salário anual (em €)',
  },

  filters: {
    add_athlete: 'Adicionar atleta',
    active_filters: 'Filtros ativos:',
    apply: 'Aplicar',
    key: 'Palavra-chave',
    custom_athletes: 'Pesquisar por Atletas',
    custom_athletes_applied: 'Da minha organização',
    custom_athletes_unapplied: 'Do Footlink',
    profile: 'Perfil',
    min_sm: 'Min.',
    mini: 'Mínimo',
    minima: 'Mínima',
    ate: 'Até',
    de: 'De',
    select_period: 'Selecionar período',
    pdf: 'PDF',
    video_link: 'Vídeo',
    a: 'à',
    max_sm: 'Max.',
    max: 'Máximo',
    maxima: 'Máxima',
    games_in: 'Jogos em',
    and_max: 'e máximo',
    contract: 'Contrato',
    pos: 'Posição',
    nation: 'Nacionalidade',
    gender: 'Sexo',
    lists: 'Atletas Monitorados',
    lists_default: 'Em qualquer lista',
    games: 'Jogos na Temporada',
    gols: 'Gols na Temporada',
    cbf_num: 'Número CBF',
    age: 'Idade',
    birt_year: 'Ano de Nascimento',
    division_ope: 'Divisão em que atua',
    country_ope: 'País em que atua',
    state_ope: 'Estado em que atua',
    clean_filters: 'Limpar Filtros',
    contract_until: 'Contrato até ',
    athl_pro: 'atletas-profissionais',
    athl: 'atletas-amadores',
    with_contr: 'com-contrato',
    no_contr: 'sem-contrato',
    gen: 'gênero',
    search: 'Busca de Atletas',
    ath_base: 'Pesquisa atletas na base mais atualizada do Brasil',
    ath: 'Atletas',
    custom_ath: 'Adicionados pela organização',
    multiple: 'Vários',
    order_by: 'Ordenar por',
    byPriority: 'Por Prioridade',
  },

  agencies: {
    birth: 'Nascimento',
    name: 'Nome',
    searchName: 'Nome da agência',
    report_name: 'Nome do Relatório',
    athlete_name: 'Nome do atleta',
    club: 'Clube',
    club_at_moment1: 'Clube no momento',
    club_at_moment2: 'da avaliação',
    email: 'E-mail',
    agency: 'Agência',
    agencies: 'Agências',
    subti: 'Confira a lista mais completa e atualizada de agentes do Brasil',
    origin_countr: 'País de Origem',
    phones: 'Telefones',
    lists: 'Listas',
    text1: 'atletas agenciados por essa organização',
    text2: 'Clube Atual',
    text3: 'Contrato Atual',
    text4: 'Clube Cedente',
    text5: 'Contrato Cedente',
    text6: 'Ainda não existem atletas adicionados nesta agência',
    text7: 'Categoria',
    text8: 'Licença FIFA',
    text9: 'Por categoria',
    text10: 'Agências verificadas',
  },

  config: {
    dire: 'Direcionamento',
    cont: 'Contratar',
    contr: 'contratar',
    mon: 'Monitorar',
    moni: 'monitorar',
    discard: 'descartar',
    toEvaluate: 'Trazer para avaliação',
    evaluate: 'trazer-para-avaliacao',
    pri: 'Prioridade',
    hi: 'Alta',
    hig: 'alta',
    dang: 'danger',
    mid: 'Média',
    mi: 'media',
    warn: 'warning',
    lo: 'Baixa',
    low: 'baixa',
    inf: 'info',
    model: 'Modelo',
    prior: 'Prior.',
    CONTRATAR: 'Contratar',
    MONITORAR: 'Monitorar',
    OBSERVAR: 'Monitorar',
    DESCARTAR: 'Descartar',
    NEGOCIAR: 'Negociar',
    'TRAZER-PARA-AVALIACAO': 'Trazer para avaliação',
    ALTA: 'Alta',
    MEDIA: 'Média',
    BAIXA: 'Baixa',    
  },

  field: {
    con: 'Concluir',
    edit: 'Editar',
    insert: 'Inserir',
    click: 'Clique aqui para inserir sua avaliação',
    click1: 'Clique aqui para inserir informações.',
    graf: 'Gráfico de Aspectos',
    pare: 'Parecer',
    conte: 'Contexto da equipe/liga em que atua',
    ava: 'Avaliação do Atleta',
    gameData: 'Dados do jogo',
    selec: 'Selecione um modelo de avaliação.',
    asp: 'Aspectos',
    re_ava: 'Avaliações Realizadas',
    add_ava: 'Adicionar Avaliação',
    text1: 'Esse atleta foi avaliado em',
    opor: 'oportunidades',
    re_ava2:
      'Confira as avaliações já realizadas para este atleta pelos analistas da sua organização e pelo Footure Pro.',
    re_ava1: 'Avaliações',
    filter1: 'Analistas: Todas',
    filter2: 'Clube no momento: Todos',
    filter3: 'Período',
  },

  match: {
    text1: 'Próximos Jogos',
    text2: 'Ainda não temos registro dos próximos jogos do atleta',
    text3: 'Jogos',
    text4: 'Jogos disputados',
    text5: 'Ainda não temos registro dos jogos do atleta',
    comp: 'Nome da Competição',
    mand: 'Mandante',
    pla: 'Placar',
    guest: 'Visitante',
    obs: 'Observador',
    sum: 'Súmula da partida',
    videos: 'Vídeos',
    text6: '+ VER TODOS OS VÍDEOS',
    availability: 'Disponibilidade',
    text7:
      'Confira as estatísticas do atleta por partidas. Também programe-se para acompanhar o atleta em seus próximos jogos.',
    text8: 'Estatísticas do Atleta na partida',
    text9: 'Gols',
    text10: 'Cartão Amarelo',
    text11: 'Dois Amarelos',
    text12: 'Cartão Vermelho',
    text13: 'Minutos Jogados',
    text14: 'Iniciou como Titular',
    text15: 'Iniciou no banco',
    text16: 'Entrou',
    text17: 'Saiu',
    text18: 'Partidas Jogadas',
    text19: 'Dois cartões amarelos',
    text20: 'Entrou no jogo',
    text21: 'Saiu do jogo',
    text22: 'Assistências',
    noResults1: 'Nenhuma partida encontrada',
    noResults2: 'com estes critérios',
  },

  overview: {
    text1: '+ Ver todas as transferências',
    text2: 'Carreira',
    text3: 'Total movimentado:',
    text4: 'Ver Atividade',
    text5: '+ VER TODAS AS AVALIAÇÕES',
    text6: '+ VER TODAS AS NEGOCIAÇÕES',
    text7: 'Negociações',
    text8: 'concluida',
    text9: 'cancelada',
    text10: 'pendente',
    text11: 'Início',
    text12: 'Responsável',
    text13: 'Último Status',
    text14: 'Contato',
    text15: 'Tipo',
    text16: 'Status',
    text17: 'Notícias',
    text18: '+ VER TODAS AS NOTÍCIAS',
    text19: '+ Ver desempenho completo',
    text20: 'Desempenho',
    text21: 'Fechar',
    text22: 'Tentar novamente',
    text23: 'Desempenho nas últimas 5 competições ',
    text24: 'Denunciar',
    text25: 'Últimas 3 transferências',
    text26: 'Disponibilidade',
    text27: 'Gols na carreira',
    text28: 'Jogos na carreira',
    text29: 'Maior Valor',
    text30: ' em ',
    text31: 'Informações do atleta',
  },

  report_pro: {
    title: 'Relatórios PRO',
    description: 'Confira os relatórios gerados por profissionais sobre o atleta. Insira o seu relatório também.',
    no_reports: 'Não há relatórios para exibir',
    image: 'Imagem',
    add_report: 'Adicionar relatório',
    edit: 'Alterar',
    dialog_h1: 'Dados do relatório',
    dialog_title: 'Título',
    dialog_type: 'Tipo de arquivo',
    dialog_type_pdf: 'Arquivo em PDF',
    dialog_type_video: 'Link para vídeo',
    file: 'Arquivo',
    video: 'Link do vídeo',
    select_file: 'Selecionar arquivo',
    access: 'acesso{{sufix}}',
    public: 'Público',
    only_agency: 'Só minha organização vê',
    when_see: 'Quem pode ver?',
    when_see_agency: 'Somente minha organização',
    delete_report: 'Excluir Relatório',
    confirm_delete_report: 'Tem certeza que deseja excluir esse relatório?',
    filter_type: 'Por tipo',
    filter_period: 'Por período',
    filter_origin: 'Por origem',
    filter_myagency: 'Somente inseridas por mim',
    button_add: 'Adicionar relatório PRO',
  },

  myAgency: {
    recomendedDimensionLogo: 'Dimensão recomendada ao logo: 200x200 px',
    agency_info: 'Informações da Agência',
    athletes_in: 'Atletas no Footlink',
    principal_market: 'Mercado Principal',
    my_athletes: 'Meus Atletas',
    my_athletes_desc: 'Gerencie as informações dos atletas da sua agência',
    athletes_profile: 'Perfil dos Atletas',
    represent: 'Representação',
    transfer: 'Transferências',
    dialog_title: 'Dados da agência',
    edit_agency: 'Editar dados da Agência',
  },

  myClub: {
    negotiations: {
      negotiations: 'Gestão de Mercado',
      subtitle: 'Mantenha controle sobre as negociações realizadas pelo seu clube.',
      originTransferMarket: 'Origem no Mercado de Transferências',
      exit: 'Saídas',
      arrivals: 'Chegadas',
      allPositions: 'Posição: Todas',
      inConversation: 'Em conversa',
      discarded: 'Descartados',
      inNegotiation: 'Em negociação',
      negotiated: 'Negociados',
      addAthlete: '+ Adicionar Atleta',
      list: 'Lista',
      report: 'Relatório',
      model: 'Modelo',
      direc: 'Direcionamento',
      priority: 'Prioridade',
    },
    general: {
      title: 'Visão Geral',
      contractTitle: 'Contratos a vencer',
      contractSeeMore: 'Ver todos os contratos',
      info: 'Informações do clube',
      emblem: 'Escudo',
      addImage: 'Adicionar imagem',
      clubData: 'Dados do Clube',
      stadium: 'Estádio',
      partnersNumber: 'Número de Sócios',
      expireIn: 'Vencendo em',
      dialogTitle: 'Editar dados do Clube',
    },
    users: {
      title: 'Usuários',
      subtitle: 'Gerencie e controle os usuários que podem acessar a conta da sua organização',
      remove: 'Remover',
      editPermission: 'Editar permissões',
      cancel: 'Cancelar',
      removeUser: 'Remover Usuário',
      warningMessage: 'Tem certeza que deseja remover o usuário',
      errorMessage: 'Ocorreu um erro ao tentar remover o usuário. Por favor, tente novamente',
    },
    professionalCast: {
      pageTitle: 'Meu Elenco',
      pageSubTitle: 'Gerencie as informações dos atletas do seu clube.',
      contractUntil: 'Contrato até',
      cedingClub: 'Clube Cedente',
      cedingContract: 'Contrato Cedente',
      loanedClub: 'Clube Empréstimo',
      loanedContract: 'Contrato Empréstimo',
    },
    profileCast: {
      title: 'Perfil do Elenco',
      pageTitle: 'Perfil do Elenco Profissional',
      pageSubTitle: 'Acompanhe o perfil de idade do elenco e a sua minutagem em campo.',
      male: 'Masculino',
      female: 'Feminino',
      filterBy: 'Filtrar por: ',
      development: 'Desenvolvimento',
      cutting: 'Lapidação',
      performance_peak: 'Pico de Performance',
      sustain: 'Sustentação',
      tutor: 'Tutor',
      minutes_played_label: 'Minutos em Campo',
      age_label: 'Idade',
      info_label: '* Este gráfico está limitado em 40 atletas.',
      athlete_all: 'Atleta: Todos',
    },
    contracts: {
      pageTitle: 'Contratos',
      pageSubTitle: 'Acompanhe o vencimento dos contratos dos atletas do seu elenco.',
      contractGroup_finished: 'Sem contrato',
      contractGroup_danger: 'Menos de {{contractHighRisk}} meses do vencimento',
      contractGroup_warning: 'Entre {{contractHighRisk}} e {{contractWarningRisk}} meses do vencimento',
      contractGroup_ok: 'Mais de {{contractWarningRisk}} meses do vencimento',
      contractGroupEmpty_finished: 'Nenhum contrato vencido',
      contractGroupEmpty_danger: 'Nenhum contrato a menos de {{contractHighRisk}} meses do vencimento',
      contractGroupEmpty_warning:
        'Nenhum contrato entre {{contractHighRisk}} e {{contractWarningRisk}} meses do vencimento',
      contractGroupEmpty_ok: 'Nenhum contrato a mais de {{contractWarningRisk}} meses do vencimento',
      contractResumeCount_finished: '{{length}} atletas',
      contractResumeCount_danger: '{{length}} contratos',
      contractResumeCount_warning: '{{length}} contratos',
      contractResumeDesc_finished: 'sem contrato',
      contractResumeDesc_danger: 'com Risco Alto',
      contractResumeDesc_warning: 'com Risco Médio',
      dialogTitle: 'Editar Alertas de Vencimento de contrato',
      dialogDesc: 'Edite os tempos de alertas de vencimento de contrato (em meses)',
      riskTitle_danger: 'Risco Alto:',
      riskTitle_warning: 'Risco Médio:',
      riskTitle_ok: 'Risco Baixo:',
      riskDescLessThan: 'Menor que',
      riskDescGreatherThan: 'Maior que',
      riskDescMonths: 'meses',
      riskDescAnd: 'e',
      tableColumn: {
        athlete: 'Atleta',
        age: 'Idade',
        position: 'Posição',
        nationality: 'Nacionalidade',
        sourceTeam: 'Clube Cedente / Contrato Clube Cedente',
        contractType: 'Tipo de contrato',
        contractNumber: 'Contrato',
        contractStart: 'Início do Contrato',
        contractEnd: 'Fim do Contrato',
      },
    },
  },

  club: {
    text1: 'Prazo do Contrato:',
    text2: 'Emprestado:',
    text3: 'Sim',
    text4: 'Não',
    text5: 'Nenhuma avaliação',
    text7: 'VALOR ATUAL DE MERCADO',
    text8: 'MAIOR VALOR DE MERCADO',
    text9: 'não informado',
    text10: 'Editar dados do atleta',
    text11: 'Agência',
    text12: 'Não informada',
    text13: 'Altura',
    text14: 'Peso',
    text15: 'Avaliação Técnica',
    text16: 'Desempenho',
    text17: 'Clube/Seleção',
    text18: 'Confira o histórico de desempenho do atleta por temporada, competição, clube e seleções.',
    box1: 'Não encontramos nada com esses termos.',
    box2: 'Dados do relatório:',
    box3: 'Relatório gerado para o projeto',
    box4: 'Descrição',
    box5: ' de 250 caracteres',
    box6: 'Conteúdo do Relatório',
    box7: 'Atletas',
    box8: 'Adicionar atleta',
    box9: 'Total Temporada ',
    box10: 'Características Gerais',
    box11: 'Negociação',
    box12: 'Agente',
    box13: 'Salário',
    box14: 'Investimento',
    box15: 'Avaliação',
    box16: 'Contexto em que atua',
    box17: 'Ainda não existem avaliações para este atleta',
    box18: 'Sem título',
    box19: 'Histórico da Carreira do Atleta',
    box20: 'Transferências',
    box21: 'CLUBE ATUAL',
    box22: 'Até ',
    box23: 'CLUBE CEDENTE',
    box24: 'Visão Geral',
    box25: 'Anotações',
    box26: 'Avaliações',
    box27: 'Meus anúncios',
    box28: 'Atletas disponíveis',
    box29: 'Minhas negociações',
    box30: 'Atletas desejados',
    noData: 'Não há atletas para exibir',
  },

  text: {
    box1: 'Clube Emprést.',
    box2: 'Cont. Emprést.',
    box3: 'Contatos',
    box4: 'Esse clube foi avaliado em ',
    box5: 'Monitoramento',
    box6: 'Notícia',
    box7: 'Adicionar notícia',
    box8: 'Competições mais recentes',
    box9: 'Colocação',
    box10: '+ VER TODAS AS COMPETIÇÕES',
    box11: 'Fonte',
    box12: 'Fundação',
    box13: 'Site Oficial',
    box14: 'Sócios',
    box15: 'Endereço',
    box16: 'CEP',
    box17: '+ VER TODOS OS CONTATOS',
    box18: 'Dirigentes',
    box19: 'Estádio',
    box20: '+ VER TODAS AS TRANSFERÊNCIAS',
    box21: 'elenco',
    box22: 'Cont. Cedente',
    box23: 'VALOR DE MERCADO',
    box24: 'Opção 1',
    box25: 'Opção 2',
    box26: 'Opção 3',
    box27: 'Período',
    box28: 'Local',
    box29: 'Resultado',
    box30: 'Elenco',
    box31: 'Emprestados',
    box32: 'Amadores',
    box33: 'Feminino',
    box34: 'Estado',
    box35: 'Cidade',
    box36: 'País',
    box37: 'Amadoras',
    box38: 'Emprestadas',
  },

  compe: {
    text1: 'País',
    text2: 'Âmbito',
    text3: 'Atual',
    text4: 'Clube competição',
    text5: 'Atletas Inscritos',
    text6: 'Nenhuma partida encontrada',
    text7: 'Publicado em ',
    text8: 'Acompanhe os destaques da ',
    text9: ' com os relatórios produzidos pelo Footure PRO.',
    text10: 'Nenhum relatório encontrado',
    text11: 'Relatórios',
    text12: 'Clubes',
    text13: 'Artilharia',
    text14: 'Ver súmula',
    text15: 'Ver vídeo',
    text16: 'Nenhum jogo encontrado',
    text17: 'Jogos na competição',
    text18: 'Cedente',
    text19: 'Inscritos',
    text20: 'Elenco na competição',
    text21: 'Organizador',
    text22: 'Região',
    text23: 'Fim',
    text24: 'Competições',
    text25: 'Somos a maior fonte de competições de base e de futebol feminino do Brasil.',
    text26: 'Profissional',
    loadMore: 'Carregar mais jogos',
    precociousAthletes: 'Atletas Precoces',
    context: {
      R: 'Regional',
      Regional: 'Regional',
      N: 'Nacional',
      Nacional: 'Nacional',
      SA: 'Sulamericano',
      Sulamericano: 'Sulamericano',
    },
    currentSeason: 'Temporada atual',
  },

  organi: {
    box1: 'Atualizar',
    box2: 'Avatar',
    box3: 'Nome da organização',
    box4: 'E-mail Geral',
    box5: 'Telefone 1',
    box6: 'Telefone 2',
    box7: 'Endereço Principal',
    box8: 'Endereço Completo',
    box9: 'Cidade',
    box10: 'Redes Sociais',
    box11: 'Atualizar configurações',
    box12: 'O Telefone 1 precisa ter DDI, DDD e número.',
    box13: 'O Telefone 2 precisa ter DDI, DDD e número.',
    box14: 'O Whatsapp precisa ter DDI, DDD e número.',
    box15: 'Avatar',
    box16: 'Nome do Estádio',
    box17: 'Nome Popular',
    box18: 'Data de Fundação',
    box19: 'Número de Sócios',
    box20: 'Selecione',
    text1: 'Configurações',
    text2: 'Gerencie a sua conta e as suas preferências',
    text3: 'Cobrança',
    text4: 'Organização',
    text5: 'Excluir avaliação',
    text6: 'Realizada em',
    text7: 'Monitore e tenha acesso rápido sempre que precisar.',
    text8: 'Exclur Avaliações',
    text9: 'Confirmar',
    text10: 'Tem certeza que deseja excluir as avaliações selecionadas?',
    text11: 'Divisão',
    text12: 'Série',
    text13: 'Atletas',
    text14: 'Valor de Mercado',
    text15: 'Clubes Estrangeiros',
    text16: 'Pesquise atletas e informações de clubes do Brasil e do Mundo',
    text17: 'começou a ',
    text18: 'o atleta',
    text19: 'gerou um novo relatório',
    text20: 'Em ',
    text21: ' às ',
    text22: 'Atleta anunciado',
    text23: 'Tipo de Negócio',
    text24: 'Valor',
    text25: 'Publicação',
    text26: 'Expiração',
    text27: 'Interessados/Visualizações',
    text28: 'Status',
    text29: 'de',
    text30: 'enviou uma mensagem sobre o anúncio do atleta',
    text31: 'inseriu',
    reportPro: 'o Relatório PRO "{{report_name}}" d',
    evaluationSaved: 'Avaliação salva com sucesso',
    desiredAthlete: 'Atleta Desejado',
    inNegotiationsOferreds: 'Em negociação / Ofertados',
  },

  home: {
    support: 'Entrar em contato com o suporte',
    box1: 'registrou',
    box2: 'do atleta',
    box3: 'no BID da CBF',
    box4: 'Em: ',
    box5: 'Até: ',
    box6: 'Liberar',
    box7: 'Registro para iniciação desportiva',
    box8: 'Rescisão',
    box9: 'Reversão',
    box10: 'Senha',
    box11: 'Esqueci minha senha',
    box12: 'Permanecer conectado',
    box13: 'Aguarde',
    box14: 'Entrar na minha conta',
    box15: 'Digite o seu e-mail',
    box16: 'Recuperar Senha',
    box17: 'Voltar',
    box18: 'Enviamos um e-mail para você gerar uma nova senha.',
    box19: 'Remover Atletas',
    box20: 'Remover',
    text1: 'Tem certeza que deseja remover todos os atletas selecionados das listas de monitoramento?',
    text2: 'Por estado',
    text3: ' - Nacional',
    text4: 'Clubes Brasileiros',
    text5: 'Todos',
    text6: 'Série A',
    text7: 'Série B',
    text8: 'Série C',
    text9: 'Série D',
    text10: 'Análise de Mercado',
    text11: 'Gerencie e organize seus processos de análise de mercado de forma colaborativa',
    text12: 'Crie o seu primeiro projeto',
    text13: 'Adicionar Projeto',
    text14: 'A nova senha e senha de confirmação devem ser iguais',
    text15: 'Senha atual',
    text16: 'Nova senha',
    text17: 'Confirmar nova senha',
    text18: 'Meu perfil',
    text19: 'Alterar senha',
    text20: 'Gerencie a seu perfil e as suas preferências',
    footlinkClubs: 'Clubes Footlink',
    footlinkClient: 'Cliente Footlink',
  },

  proj: {
    campo: 'Campo',
    linkProjetos: 'Projetos',
    linkRelatorios: 'Relatórios',
    linkMonitoramento: 'Monitoramento',
    linkAvaliacoes: 'Avaliações',
    linkJanelasTransferencias: 'Janelas de Transferências',
    box1: 'Ver Perfil',
    box2: 'Editar Relatório',
    box3: 'Histórico de Comentários',
    box4: 'Importar comentários selecionados',
    box5: 'Importar',
    box6: 'ADICIONADO POR',
    box7: 'INDICADO POR',
    box8: 'em 00/00/000',
    box9: 'Indicador por',
    box10: 'Teste',
    box11: 'Comentários',
    box12: 'Você já fez',
    box13: 'comentários dentro do perfil desse atleta.',
    box14: 'Ver comentários',
    box15: 'Proprietário',
    box16: 'criou este projeto',
    box17: 'Não há qualquer histórico para exibir',
    box18: 'Monitorando',
    box19: 'Ocorreu algum problema ao sincronizar o Workflow, por favor atualize sua página',
    box20: 'atletas',
    text1: '+ Adicionar atleta',
    text2: '+ Adicionar lista',
    text3: 'Crie o seu primeiro Time Sombra',
    text4: 'Adicionar time sombra',
    text5: 'Ver time sombra',
    text6: 'Time Sombra',
    text7: 'Esquema: Todos',
    text8: 'Nenhum atleta para exibir',
    text9: 'Digíte um título',
    text10: 'Selecione o esquema: ',
    text11: 'Ocorreu algum problema durante a comunicação com o servidor, favor tente novamente mais tarde',
    text12: 'Nenhum atleta definido para esta posição',
    text13: 'Favor, selecione a posição desejada para o relatório',
    text14: 'Selecionar Atleta',
    text15: 'Etapa',
    text16: 'Atletas nessa posição',
    text17: 'Teste 1',
    text18: 'Teste 2',
    text19: 'Teste 3',
    text20: '...Carregando',
    text21: 'Workflow',
    text22: 'Organize e personalize o seu processo de análise de mercado',
    text23: 'Esquema',
    text24: 'Nome do Atleta',
    create: 'Criar',
    proj: 'Projeto',
    pageTitleTimeSombra: 'Times Sombra',
    pageDescTimeSombra: 'Crie times sombra a partir dos atletas selecionados no seu projeto.',
    filtrarPor: 'Filtrar por: ',
    filtarPorPlaceholder: 'Nome do time sombra',
    ultimaAtualizacao: 'Última Atualização: ',
    montarTimeSombra: 'Montar Time Sombra',
    montarTimeSombraDesc: 'Escolha o seu modelo campo, esquema e cor do campo e monte o seu Time Sombra.',
    nomeTimeSombra: 'Nome do Time Sombra: ',
    corDoCampo: 'Cor do Campo: ',
    corDoCampoVerde: 'Verde',
    corDoCampoEscuro: 'Escuro',
    corDoCampoBranco: 'Branco',
    salvarContinuar: 'Salvar e continuar',
    esquema: 'Esquema: ',
    modeloDeCampo: 'Modelo de Campo: ',
    modeloStandard: 'Padrão',
    modeloArea: 'Áreas',
    modeloRunner: 'Corredores',
    editarTimeSombra: 'Editar Time Sombra',
    adicionarPosicao: 'Adicionar Posição',
    editarPosicao: 'Posição ',
    posicaoPanelTitle: 'Atletas da posição',
    posicaoInputNome: 'Nome da posição',
    adicionarAtleta: 'Adicionar Atleta',
    atletaPanelTitle: 'Selecionar Atleta',
    atletaFiltroNome: 'Nome do atleta',
    atletaFiltroEtapaTodos: 'Etapa: Todas',
    atletaFiltroPosicaoTodos: 'Posição: Todas',
    atletaCorAmarelo: 'Amarelo',
    atletaCorVerde: 'Verde',
    atletaCorAzul: 'Azul',
    atletaCorVermelho: 'Vermelho',
    atletaCorCinza: 'Cinza',
    erroSchema: 'Por favor, defina o Esquema desejado',
    erroNome: 'Por favor, defina o Nome desejado',
    historyTitle: 'Histórico',
    historySubTitle: 'Mantenha o controle sobre todas as atividades do seu projeto.',
    userTitle: 'Usuários',
    userSubTitle: 'Gerencie a equipe que trabalha no projeto.',
    deleteTitle: 'Excluir Lista',
    deleteMessage:
      'A exclusão da coluna irá excluir todos os atletas desta e essa ação não poderá ser revertida. Tem certeza de que quer continuar com a exclusão?',
    applyFilters: 'Aplicar',
    linkMercadoTransferencia: 'Mercado de Transferências',
    subMercadoTransferencia: 'Negocie atletas de forma direta e com mais transparência.',
    modalUploadImagem: 'Resolução recomendada',
    indicatedByEditing: ' (Clique para editar)',
    confirmationDeleteModal: 'Tem certeza que deseja excluir todo este projeto?',
    orderCreatedAtAsc: 'Criação - Mais Antiga',
    orderCreatedAtDesc: 'Criação - Mais Recente',
    orderUpdatedAtAsc: 'Atualização - Mais Antiga',
    orderUpdatedAtDesc: 'Atualização - Mais Recente',
    orderNameAsc: 'Ordem alfabética - A > Z',
    orderNameDesc: 'Ordem alfabética - Z > A',
    removeAthlete: 'Remover atleta',
    removeAthleteMessage: 'Tem certeza que deseja remover este atleta?',
    athleteProjectCount: 'Está presente em {{count}} projeto',
  },

  user: {
    box1: 'E-mail enviado com sucesso!',
    box2: 'Informe um e-mail válido',
    box3: 'Procurar por nome',
    box4: 'Perfil',
    box5: 'Adicionar',
    box6: 'Não está listado acima? Convide por e-mail.',
    box7: 'Digite o e-mail da pessoa',
    box8: 'Editar Usuários',
    box9: 'Alguns minutos atrás',
    box10: 'hora(s) atrás',
    box11: 'dia(s) atrás',
    box12: 'Adicionado em',
    box13: 'Última atividade',
    box14: 'Colaboradores',
    box15: 'Gerenciar usuários',
    box16: 'Criado em ',
    box17: 'Última atualização em ',
    box18: 'por',
    box19: 'Visualizador',
    box20: 'Administrador',
    text1: 'Editor',
    text2: 'Histórico',
    text3: 'Usuários',
    text4: 'Nenhum resultado encontrado',
    text5: 'Nome do Projeto',
    text6: 'Adicionar Projeto',
    text7: 'Nenhum atleta adicionado',
    text8: 'Ver relatório',
    text9: 'Atletas no Relatório',
    text10: 'Excluir Relatórios',
    text11: 'Excluir',
    text12: 'Tem certeza que deseja excluir os relatórios selecionados?',
    text13: 'Sua senha foi alterada. Faça o login utilizando a nova senha.',
    text14: 'Digite a nova senha',
    text15: 'Confirme a nova senha',
    text16: 'Masculino',
    text17: 'Amador',
    text18: 'Aberto',
    text19: 'Fechado',
    text20: 'Por País',
    text21: 'Modalidade',
    text22: 'Por Modalidade',
    text23: 'Por Status',
    text24: 'Início Temporada',
    text25: 'Fim Temporada',
    text26: 'Janela 1 (Início/Fim)',
    text27: 'Janela 2 (Início/Fim)',
    text28: 'Janela 3 (Início/Fim)',
    text29: 'Janelas',
    text30: 'Fique por dentro das janelas de transferências de todos os países',
    text31: 'Nome do Atleta',
    text32: 'Meus anúncios',
    text32sub: 'Maximize suas oportunidades de negociação de atletas com nossa solução de gerenciamento de anúncios.',
    text33: 'Adicionar Anúncio',
  },

  login: {
    alreadyLoggedText1: 'Sua conta no Footlink está em uso em outro device.',
    alreadyLoggedText2: 'Para sua segurança, não permitimos acessos simultâneos com a mesma conta.',
    alreadyLoggedText3: 'Deseja forçar o login e encerrar todas as sessões abertas?',
    forceLogin: 'Sim, forçar login',
  },

  app: {
    box1: 'Ver todas as atividades',
    box2: 'Notificações e Alertas ',
    box3: 'Mais notificações',
    box4: 'Ver clube',
    box5: 'Sair',
    box6: 'Ver todos',
    box7: 'Digite o nome de um atleta ou clube (pelo menos 3 caracteres)',
    box8: 'BUSCA AVANÇADA',
    box9: 'Janelas ativas',
    box10: 'Ver todas as janelas',
    box12: 'PRO',
    box13: 'Página Inicial',
    box14: 'Atletas e Clubes',
    box15: 'Base',
    box16: 'Competições',
    box17: 'Copinha 2023',
    box18: 'Análise de Mercado',
    box19: 'Listagem de Agências',
    box20: 'Configuração do Relatório',
    text1: 'Edição',
    text2: 'Preview',
    text3: 'Salvando alterações...',
    text4: 'Alterações salvas com sucesso!',
    text5: 'de',
    text6: 'por página',
    text7: 'Itens',
    text8: 'Conversas',
    text9: 'Criar anúncio',
  },

  preferentialFoot: {
    null: '',
    Ambos: 'Ambos',
    Direito: 'Direito',
    Esquerdo: 'Esquerdo',
    Indiferente: 'Indiferente',
    undefined: 'Não informado',
  },

  performance: {
    ABAIXO: 'Abaixo do nível da partida',
    MEDIO: 'Na média do nível da partida',
    ACIMA: 'Acima do nível da partida',
    MUITO_ACIMA: 'Muito acima do nível da partida',
    null: '',
    undefined: 'Não informado',
  },

  positionOptions: {
    null: '',
    Atacante: 'Atacante',
    Ataque: 'Ataque',
    Centroavante: 'Centroavante',
    Defesa: 'Defesa',
    Goleiro: 'Goleiro',
    LateralDireito: 'Lateral Direito',
    LateralEsquerdo: 'Lateral Esquerdo',    
    Meia: 'Meia',
    MeiaAtacante: 'Meia-atacante',
    'Meia-atacante': 'Meia-atacante',
    MeioCampo: 'Meio-campo',    
    Volante: 'Volante',
    Zagueiro: 'Zagueiro',
    ExtremoEsquerdo: 'Extremo Esquerdo',
    ExtremoDireito:'Extremo Direito',
    'Lateral Direito': 'Lateral Direito',
    'Lateral Esquerdo': 'Lateral Esquerdo',
    'Meio-campo': 'Meio-campo',
    'Extremo Esquerdo': 'Extremo Esquerdo',
    'Extremo Direito':'Extremo Direito',
    Outro: 'Outro',
    undefined: 'Não informado',    
  },

  positionOptionsRaw: {
    null: '',
    Atacante: 'Atacante',
    Ataque: 'Ataque',
    Centroavante: 'Centroavante',
    Defesa: 'Defesa',
    Goleiro: 'Goleiro',
    'Lateral Direito': 'Lateral Direito',
    'Lateral Esquerdo': 'Lateral Esquerdo',
    Meia: 'Meia',
    'Meia-atacante': 'Meia-atacante',
    'Meio-campo': 'Meio-campo',
    Volante: 'Volante',
    Zagueiro: 'Zagueiro',
    undefined: 'Não informado',
  },

  newDivisionOptions: {
    1: '1ª Divisão',
    2: '2ª Divisão',
    3: '3ª Divisão',
    4: '4ª Divisão',
    division: 'Divisão',
    UmDivisao: '1ª Divisão',
    DoisDivisao: '2ª Divisão',
    TresDivisao: '3ª Divisão',
    QuatroDivisao: '4ª Divisão',
    SemDivisao: 'Sem Divisão',
  },

  errorMessage: {
    expiredAccount: 'Sua conta expirou, favor entre em contato para renovar seu acesso. Você pode encontrar nosso contato através do site footlink.app',
    serviceUnavailable: 'Serviço indisponível. Tente novamente mais tarde.',
    defaultCredentialsError: 'Não pudemos validar suas credenciais. Tente novamente.',
    resourceNotFoundAth: 'Atleta não encontrado.',
    resourceNotFoundClub: 'Clube não encontrado.',
    maximumAthleteReached: 'Número máximo de atletas agenciados atingido.',
    minMax: 'Máx deve ser maior que Min',
  },

  SystemInfoName: {
    teams: 'Ti',
    athletes: 'F',
    market_value: 'M',
    competitions: 'M',
    transfers: 'Transferências de atletas fora do Brasil',
    bid: 'Contratos - BID da CBF',
    historical_data_prof: 'Estatísticas de jogos (Profissional)',
    historical_data_base: 'Estatísticas de jogos (Base)',
    match_data: 'M',
    transfer_windows: 'Janelas de transferências',
  },

  yesNo: {
    nao: 'Não',
    sim: 'Sim',
  },

  reportCheckboxes: {
    generalFeatures: 'Características Gerais',
    negotiation: 'Negociação',
    images: 'Imagens',
    evaluations: 'Avaliações',
    historic: 'Desempenho',
    career: 'Carreira',
  },

  targetingOptions: {
    Contratar: 'Contratar',
  },

  radioAdvancedSearch: {
    female: 'Feminino',
    male: 'Masculino',
  },

  radioAdvancedSearchCustomAthlete: {
    custom: 'Adicionados pela minha organização',
    normal: 'Do banco de atletas do Footlink',
  },

  contractOptions: {
    'com-contrato': 'Com Contrato',
    'sem-contrato': 'Sem Contrato',
  },

  profileOptions: {
    'atletas-profissionais': 'Atletas Profissionais',
    'atletas-amadores': 'Atletas Amadores',
  },

  negotiationOptions: {
    SALE: 'Venda',
    LOAN: 'Empréstimo',
  },

  desiredNegotiationOptions: {
    SALE: 'Compra',
    LOAN: 'Empréstimo',
  },

  monitoredAthlete: {
    monitorado: 'Sim',
    naomonitorado: 'Não',
  },

  europeanPassport: {
    'possui-passaporte': 'Sim',
    'nao-possui-passaporte': 'Não',
  },

  erros: {
    text1: 'Dados não encontrados.',
    text2: 'Não foi possível buscar as agências no momento.',
    text3: 'Não foi possível buscar os detalhes da agência no momento',
    text4: 'Não foi possível buscar os atletas desta agência no momento.',
    text5: 'Atleta marcado para sua agência com sucesso!',
    text6: 'Denúncia enviada com sucesso!',
    text7: 'Atleta desmarcado com sucesso!',
    text8: 'Não foi possível realizar a ação no momento',
    text9: 'Atleta',
    text10: 'Falha na carga da avaliação.',
    text11: 'Ocorreu um erro ao tentar buscar as notas. Por favor, tente novamente',
    text12: 'Ocorreu um erro ao tentar criar a nota. Por favor, tente novamente',
    text13: 'Não foi possível encontrar a lista de atletas.',
    text14: 'Não foi possível encontrar o histórico de desempenho do atleta.',
    text15: 'Não foi possível encontrar os jogos do atleta.',
    text16: 'Não foi possível encontrar o histórico de contratos na CBF do atleta.',
    text17: 'Não foi possível encontrar o histórico de transferências do atleta.',
    text18: 'Ocorreu um erro ao tentar atualizar o atleta. Por favor, tente novamente',
    text19: 'Não foi possível encontrar a lista de países.',
    text20: 'Não foi possível buscar as avaliações no momento. Por favor, tente novamente',
    text21: 'Não foi possível excluir as avaliações no momento. Por favor, tente novamente',
    text22: 'Ocorreu um erro ao tentar buscar o últimos atletas visualizados. Por favor, tente novamente',
    text23: 'Ocorreu um erro ao tentar buscar as últimas movimentações. Por favor, tente novamente',
    text24: 'Ocorreu um erro ao tentar buscar os detalhes do sistema. Por favor, tente novamente',
    text25: 'Ocorreu um erro ao tentar buscar as janelas de transferência. Por favor, tente novamente',
    text26: 'Não foi possível encontrar as listas de monitoramento.',
    text27: 'Não foi possível salvar a lista de monitoramento.',
    text28: 'Não foi possível alterar as listas de monitoramento.',
    text29: 'Não foi possível adicionar ao monitoramento',
    text30: 'Ocorreu um erro ao tentar atualizar a organização. Por favor, tente novamente',
    text31: 'Não foi possível buscar os projetos no momento',
    text32: 'Não foi possível buscar os detalhes do projeto no momento',
    text33: 'Ocorreu um erro ao tentar buscar os relatórios. Por favor, tente novamente',
    text34: 'Ocorreu um erro ao tentar buscar o relatório. Por favor, tente novamente',
    text35: 'Ocorreu um erro ao tentar gerar o PDF do relatório. Por favor, tente novamente',
    text36: 'Ocorreu um erro ao tentar gerar o relatório. Por favor, tente novamente',
    text37: 'Ocorreu um erro ao tentar atualizar o relatório. Por favor, tente novamente',
    text38: 'Não foi possível excluir os relatórios no momento. Por favor, tente novamente',
    text39: 'Não foi possível encontrar a lista de times.',
    text40: 'Os dados dos filtros não foram encontrados',
    text41: 'Usuário e/ou senha inválidos',
    text42: 'Dados inválidos',
    text43: 'Ocorreu um erro ao tentar buscar os dados do perfil. Por favor, tente novamente',
    text44: 'Ocorreu um erro ao tentar atualizar o perfil. Por favor, tente novamente',
    text45: 'Senha atualizada com sucesso!',
    text46: 'Ocorreu um erro ao tentar trocar a senha. Por favor, tente novamente',
    text47: 'Falha na carga do schema de avaliação.',
    text48: 'Dados de inscritos da competição não encontrados.',
    text49: 'Os dados estão incorretos. Tente novamente.',
    text50: 'Formato de imagem não suportado envie imagens no formato *.png ou *.jpg ou *.jpeg ou *.gif ou *.bmp ou *.webp.',
    link: 'As redes sociais/site devem ser preenchidos com um link válido',
    generalForm: 'Todos os campos com * devem ser preenchidos',
    ageMinMax: 'A idade mínima deve ser menor que a idade máxima',
  },

  rest: {
    box1: 'Recortar imagem para o upload',
    box2: 'Faça a primeira anotação para o atleta',
    box3: 'Digite sua observação',
    box4: 'neste projeto',
    box5: 'Nota',
    box6: 'Imagens',
    box7: 'Adicionar arquivo',
    box8: 'Incluir Parecer',
    box9: 'Incluir Contexto',
    box10: 'Incluir aspectos',
    box11: 'Incluir microaspectos',
    box12: 'Selecione alguma avaliação da lista',
    box13: 'Não existem dados disponíveis sobre a carreira do atleta',
    box14: 'Não existem dados disponíveis sobre as transferencias do atleta',
    box15: 'Não existem dados disponíveis sobre os contratos do atleta',
    box16: 'Editar Imagem',
    box17: 'Inserir Imagem',
    box18: 'atletas analisados',
    box19: 'Ocorreu algum problema ao remover o atleta, favor tente novamente',
    box20: 'Ocorreu algum problema ao atualizar os dados do atleta, favor tente novamente',
    box21: 'atleta',
    box22: 'Excluir todos',
    box23: 'Nenhuma anotação inserida.',
    box24: 'Passo 1: Selecione o atleta a ser anunciado',
    box24other: '* Você só pode anunciar atletas sem contrato',
    box25: 'Continuar',
    box26: 'Audiência',
    box27: 'clubes selecionados',
    box28: 'Período de publicação',
    box29: 'Tipo de negociação desejada',
    box30: 'Valor de venda',
    box31: 'Salário anual do atleta',
    box32: 'Observações',
    box33: 'Valor do empréstimo',
    box34: 'Pesquisar',
    box35: 'Adicionar nova lista',
  },

  trello: {
    box1: 'Saiba mais sobre os maiores clubes do mundo e seus atletas.',
    box2: 'Ainda não tem acesso? Solicite uma demonstração.',
    box3: 'Quero conhecer o Footlink',
    box4: 'O futuro do seu jogo passa por aqui.',
    box5: 'Confira informações sobre os clubes brasileiros e seus elencos.',
    box6: 'Confira os clubes que são clientes Footlink.',
  },

  languages: {
    br: 'Português (Brasileiro)',
    pt: 'Português',
    eng: 'English',
    esp: 'Español',
  },

  professionals: {
    topbarTitle: 'Rede de Profissionais',
    topbarSubtitle: 'Gerencie e desenvolva seu network com a maior rede de profissionais de futebol do Brasil.',

    filtersTopspan: 'Já somos uma rede de 1.450 profissionais.',
    filtersInputName: 'Nome',
    filtersSelectLevel: 'Nível: Todos',
    filtersSelectOrganization: 'Organização: Todos',

    connectButton: 'Conectar',
    connectedButton: 'Conectado',
    pendingButton: 'Pendente',
    sendMessageButton: 'Enviar mensagem',

    inviteTitle: 'Convites',
    inviteSpan: 'Convide seus contatos para fazer parte da\n maior rede de profissionais de futebol do Brasil.',
    inviteButton: 'Enviar convite',
    inviteAccept: 'Aceitar',
    inviteIgnore: 'Ignorar',
    invite: 'Não há convites para exibir',

    networkTitle: 'Minha Rede',
    networkSpan: 'Gerencie e amplie o seu network com o Footure Pro.',
    networkConnections: 'Conexões',
    networkFollow: 'Seguir',
    networkFollowing: 'Seguindo',
    networkFollowers: 'Seguidores',

    description: 'Descrição',
    socialMedia: 'Redes sociais',
    language: 'Idiomas',
    connections: 'Conexões próximas',
    commonConnections: '+ Ver todas as conexões comuns',
  },

  listTranslation: {
    Amateur: 'Amador',
    Female: 'Feminino',
    Male: 'Masculino',
  },

  navDrawer: {
    myperfil: 'Meu perfil',
    organization: 'Organização',
    module: 'Módulos',
    name: 'Nome do Clube',
    perfil: 'ver perfil da organização',
    feed: 'Meu Feed',
    search: 'Busca de Atletas',
    club: 'Clubes',
    meuClube: 'Meu Clube',
    competitions: 'Competições',
    analysis: 'Análises de Mercado',
    market: 'Mercado de Transferência',
    rede: 'Rede de Profissionais',
    agencies: 'Agências',
  },

  chat: {
    placeholder: 'Pesquisar Mensagens',
    from: 'Você',
    select_message: 'Selecione uma conversa',
    view_club_page: 'Ver página do clube',
    view_athlete_page: 'Ver página do atleta',
    view_agent_page: 'Ver página da Agência',
    organization_messages: 'Mensagens da organização',
    my_messages: 'Minhas mensagens',
    connections: 'Conexões',
    negotiations: 'Negociações',
    clubs: 'Clubes',
    athletes: 'Atletas',
    agents: 'Agentes',
    message_clube: 'Falar com clube',
    select_category: 'Selecione uma categoria',
    no_chat: 'Nenhuma conversa encontrada',
    talk_to_agent: 'Falar com agente',
    talk_to_club: 'Falar com clube',
    athlete_club: 'Clube do Atleta',
    athlete_agency: 'Agência do Atleta',
    contract_until: 'Até',
    deleted: 'Mensagem apagada',
    deletedNote: 'Nota deletada',
  },

  transferMarket: {
    offeredBy: 'Oferecido Por',
    attention: 'Atenção !',
    dialogMessage: 'Ao demonstrar interesse por um atleta, uma nova negociação iniciará e o nome do seu Clube será exibido na negociação mesmo que esteja como "Anônimo".',
    wantToContinue: 'Deseja prosseguir?',
    search_page_desc: 'anunciados no Mercado de Transferências com esses atributos no momento',
    search_page_button: 'Ver Mercado de Transferências',
    subtitle: 'Negocie com os maiores clubes do Brasil e do mundo e reforce a sua equipe.',
    available_negotiation: ' atletas disponíveis para negociação',
    filterMessagesPlaceholder: 'Pesquisar mensagens',
    filterTransferTypePlaceholder: 'Todos atletas',
    filterTransferTypeBuy: 'Chegadas',
    filterTransferTypeSell: 'Saídas',
    filterTransferTypeSALE: 'Venda',
    filterTransferTypeLOAN: 'Empréstimo',
    editAd: 'Editar Anúncio',
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Preço Pedido',
    headerTransferTypeTitle: 'Tipo de Negociação',
    headerSalaryTitle: 'Salário',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venda',
    headerClubObs: 'Observações do anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Digite sua mensagem',
    headerOtherChatTitle: '3 conversas sobre',
    headerOtherChatAlertTitle: 'Existem outras {{length}} conversas abertas sobre este jogador',
    headerOtherChatAlertLink: 'Ver conversas',
    headerChatInactive: 'Esse atleta não está mais no mercado de transferências',
    moreInfo: 'Mais Informações',
    announcedIn: 'Anunciado em',
    business: 'Negócio',
    amountRequested: 'Preço Pedido',
    sale: 'Venda',
    year: 'Ano',
    clubAnnouncement: 'Detalhes do Anúncio',
    active: 'Ativo',
    inactive: 'Inativo',
    adData: 'Dados do Anúncio',
    defineAudience: 'Definir Audiência',
    confirmAd: 'Confirmar Anúncio',
    publish: 'Publicar',
    publishSuccess: 'Anúncio publicado com sucesso!',
    step1title: 'Passo 1: Informe as características do atleta que você está procurando',
    step2title: 'Passo 2: Digite os dados do seu anúncio',
    step3title: 'Passo 3: Defina quem pode ver o anúncio',
    step4title: 'Passo 4: Confirme os dados do anúncio antes de publicar',
    step1error: 'Selecione um atleta para continuar',
    step2error: 'Por favor preencha todos os campos antes de continuar',
    select: 'Selecionar',
    selected: 'Selecionado',
    adAuciton: 'Audiência do Anúncio',
    showAll: 'Todos podem ver',
    showAgents: 'Mostrar anúncio para Agentes',
    showAllClubs: 'Todos os clubes podem ver',
    selectShow: 'Definir quem pode ver',
    selectClubsShow: 'Definir quais clubes podem ver',
    negotiationType: 'Tipo de Negociação Desejada',
    sale_value: 'Preço de Venda',
    sale_value_desired: 'Valor da Compra',
    loan_value: 'Valor do Empréstimo',
    annual_salary: 'Salário Anual do Atleta',
    gross_salary: 'Salário Bruto',
    perYear: 'por ano',
    perMonth: 'por mês',
    publishFail: 'Falha ao publicar o anúncio!',
    completeProfile: 'Ver perfil completo',
    evaluationFooture: 'AVALIAÇÂO FOOTURE',
    openChat: 'Abrir conversas',
    athleteInfo: 'Informações do Atleta',
    modalError1: 'Não é possível criar mais de um anúncio para o mesmo atleta.',
    modalError2: 'Por favor, selecione um atleta para continuar.',
    modalError3: 'Limite de plano excedido.',
    modalError4: 'Usuário ativo em outro dispositivo.',
    withoutClub: 'Sem clube',
    announceType: 'Qual o tipo de anúncio você quer criar?',
    mySquadAthlete: 'Anunciar atleta do meu elenco',
    AthleteProfile: 'Anunciar perfil de atleta desejado',
    gbe: 'GBE',
    moreCharacteristics: 'Mais características',
    nameConfidential: 'Manter nome do meu clube em sigilo?',
    infoNameConfidential: 'Ao marcar como "Sim" o nome do seu clube aparecerá como "Sigiloso" na lista de anúncios. O nome do seu clube só aparecerá para os clubes ou agentes dos atletas que você demonstrar interesse.',
    atlheteOffers: 'Ofertas de Atletas',
    athletesOffered: 'Atletas Oferecidos',
    athletesInConversation: 'Atletas em conversa',
    discardedAthletes: 'Atletas Descartados',
    movedTo: 'Este atleta foi movido para a aba ',
    showInterest: 'Demonstrar Interesse',
    discard: 'Descartar',
    interestedAgency: 'Agência interessada',
    interestedClub: 'Clube interessado',
    clubObservations: 'Observações do Clube',
    maxValue: 'Valor máximo',
    maxSalary: 'Salário Bruto Máximo',
    clubAnnounce: 'Anúncio do Clube',
    desiredProfile: 'Perfil do Atleta desejado',
    otherFeatures: 'Outras Características',
    advancedSearch: {
      desiredAthletes: 'Atletas Desejados',
      subtitle: 'Negocie com os maiores clubes do Brasil e do mundo e faça ótimos negócios.',
      postedIn: 'Postado em',
      desiredPosition: 'Posição Desejada',
      age: 'Idade',
      profile: 'Perfil',
      sex: 'Sexo',
      club: 'Clube',
      clubSeries: 'Série do Clube',
      country: 'País',
      business: 'Negócio'
    },
    athleteList: {
      youHave1: 'Você tem ',
      youHave2: ' atletas que se enquadram no perfil desejado',
      offered: 'Atleta Oferecido',
      toOffer: 'Oferecer Atleta',
      goToNegotiation: 'Ir Para Negociação'
    },
    textError1: 'Nenhum atleta foi oferecido até o momento',
    textError2: 'Você não tem atletas com o perfil buscado pelo clube'
  },

  excededPlanLimit: {
    h4_1: 'Faça um upgrade na sua conta e',
    h4_2: 'tenha acesso a esse e outros módulos!',
    h5_1: 'Confira os benefícios de fazer um upgrade na sua conta Footlink:',
    h5_2: 'E muito mais!',
    h6_1: 'Acesso à maior base de dados de atletas brasileiros do mercado',
    h6_2: 'Acesso à dados atualizados de vencimento de contrato de atletas',
    h6_3: 'Acesso ao maior acervo de dados de competições de base do Brasil',
    h6_4: 'Ferramentas para digitalizar e organizar o seu processo de análise de mercado',
    button: 'Converse com um consultor Footlink',
  },

  negotiations: {
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Valor pedido',
    headerTransferTypeTitle: 'Tipo de negócio',
    headerSalaryTitle: 'Salário',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venda',
    headerSaleTransferTypeText: 'Venda',
    headerLoanTransferTypeText: 'Empréstimo',
    headerClubObs: 'Observações do anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Digite sua mensagem',
    headerOtherChatTitle: '3 conversas sobre',
    headerOtherChatAlertTitle: 'Existem outras 3 conversas abertas sobre esse atleta',
    headerOtherChatAlertLink: 'Ver conversas',
    errorChatCreate: 'Não foi possível salvar sua mensagem, verifique a mensagem e tente novamente',
    moreInfo: 'Mais Informações',
    announcedIn: 'Anunciado Em',
    business: 'Negócios',
    amountRequested: 'Valor pedido',
    sale: 'Venda',
    year: 'Ano',
    clubAnnouncement: 'Detalhes do Anúncio',
    active: 'Ativo',
    inactive: 'Inativo',
    adData: 'Dados do Anúncio',
    defineAudience: 'Definir Audiência',
    confirmAd: 'Confirmar Publicação',
    publish: 'Publicar',
    publishSuccess: 'Anúncio publicado com sucesso!',
    confidentialAd: 'Anúncio Sigiloso?',
    step1title: 'Passo 1: Informe as características do atleta que você está procurando',
    step2title: 'Passo 2: Digite os dados do seu anúncio',
    step3title: 'Passo 3: Defina quem poder poderá ver o anúncio',
    step4title: 'Passo 4: Confirme os dados do anúncio antes de publicar',
    select: 'Selecionar',
    selected: 'Selecionado',
    adAuciton: 'Audiência do Anúncio',
    showAll: 'Todos podem ver',
    selectShow: 'Definir quem pode ver',
    negotiationType: 'Tipo de negociação desejada',
    sale_value: 'Valor da venda',
    loan_value: 'Valor do empréstimo',
    annual_salary: 'Salário anual do atleta',
    perYear: 'por ano',
    noMessages: 'Ainda não há mensagens nesta conversa.',
    selectChat: 'Selecione uma conversa para começar a negociar!',
  },

  search: {
    typeNegotiation: 'Tipo de Negociação',
    valueForSale: 'Valor para Venda (em €)',
    valueForPurchase: 'Valor para Compra (em €)',
    salary: 'Salário (em €)',
  },

  divisionOptions: {
    division: 'Série',
    1: 'Série A',
    2: 'Série B',
    3: 'Série C',
    4: 'Série D',
  },

  transferWindows: {
    onlyMonitored: 'Somente monitoradas',
    onlyOpen: 'Somente abertas',
  },

  whatsapp: {
    phone: '5551995145721',
    valuation1: 'Gostaria de comprar a informação do valuation do atleta ',
    valuation2: ' do ',
    upgrade: 'Olá, quero dar um upgrade na minha conta do Footlink!',
  },

  criteriaOptions: {
    '': 'Nenhum critério',
    season: 'Por temporada',
    competition: 'Por competição',
    team: 'Por clube',
    teamInComp: 'Por clube na competição',
    birthyear: 'Por ano de nascimento',
    position: 'Por posição',
    minutes_played: 'Minutos Jogados',
    athlete__birthdate: 'Data de Nascimento',
  },

  clubDivisionOptions: {
    undefined: 'Divisão',
    1: '1a Divisão - Nacional',
    2: '2a Divisão - Nacional',
    3: '3a Divisão - Nacional',
    4: '4a Divisão - Nacional',
  },

  agencyAthletes: {
    title: 'Atletas agenciados',
    subTitle: 'atletas no portfólio dessa agência',
  },

  firstAccess: {
    text1: 'Parabéns',
    text2: 'você recebeu uma',
    text3: 'licença CBF',
    text4: 'para se conectar ao Footlink!',
    text5:
      'Você está a um passo de se conectar ao Footlink, a plataforma mais completa do Brasil para gestão do seu projeto esportivo.',
    text6: 'Para ativar a sua conta no Footlink é rápido, simples e descomplicado.',
    text7: 'Vamos começar?',
    text8: 'Iniciar ativação da conta',
    text9: 'Para começar',
    text10: 'insira a ',
    text11: 'senha provisória',
    text12: ' que ',
    text13: 'você recebeu por e-mail',
    text14: 'Senha provisória',
    text15: 'Enviar',
    text16: 'Agora, insira abaixo o código que',
    text17: 'você recebeu no e-mail',
    text18: 'Código',
    text19: 'Não recebeu o código? Você pode solicitar novamente em ',
    text20: 'Não recebeu o código? ',
    text21: 'Senha inválida ou usuário já está ativo.',
    text22: 'Dados Pessoais',
    text23: 'Visível somente para pessoas da sua organização',
    text24: 'Criar Senha',
    text25: 'Por sua segurança, altere com frequência a sua senha',
    text26: 'Sua senha deve ter números, letras e caracteres especiais e ter no mínimo 8 caracteres.',
    text27: 'Estou de acordo com os termos de uso e politica de privacidade',
    box1: 'Solicite o envio novamente.',
    box2: 'Confirme a sua organização',
    box3: 'Para podermos prosseguir com a ativação da sua conta, por favor verifique se a sua',
    box4: ' organização está correta.',
    box5: 'Sim, está correta!',
    box6: 'Essa não é a sua organização? Entre em ',
    box7: 'contato conosco',
    box8: 'Crie o seu perfil',
    box9: 'Agora crie o seu perfil e os seus dados de acesso',
    box10: 'Criar perfil',
    box11: 'Bem-vindo ao ',
    box12: 'Agora você tem acesso à plataforma mais completa do Brasil',
    box13: 'Para começar a utilizar o Footlink, faça login na plataforma com os dados de acesso',
    box14: 'Entre com seus dados de acesso',
    box15: 'Quer conhecer mais o Footlink? Veja o vídeo abaixo:',
    box16: 'Já tem uma conta?',
    box17: 'Entrar',
    box18: 'Footlink',
    box19: 'para fazer a gestão do seu projeto esportivo.',
    box20: 'que você cadastrou agora.',
    errorUser: 'Todos os campos são obrigatórios.',
    errorPassword: 'Por favor, verifique sua senha.',
    tokenError: 'Token inválido ou usuário já está ativo.',
  },

  modalErroPermissionProjeto: {
    text1: 'Você não tem permissão para ver esse projeto.',
    text2: 'Entre em contato com os colaboradores do projeto para solicitar acesso.',
  },

  foot: {
    right: 'Direito',
    left: 'Esquerdo',
    ambidextrous: 'Ambidestro',
    indifferent: 'Indiferente',
  },

  nationalities : {
    afghan: 'Afegão',
    albanian: 'Albanês',
    algerian: 'Argelino',
    american: 'Americano',
    andorran: 'Andorrano',
    angolan: 'Angolano',
    argentine: 'Argentino',
    armenian: 'Armênio',
    australian: 'Australiano',
    austrian: 'Austríaco',
    azerbaijani: 'Azerbaijano',
    bahamian: 'Bahamense',
    bahraini: 'Barenita',
    bangladeshi: 'Bangladeshiano',
    barbadian: 'Barbadiano',
    belarusian: 'Bielorrusso',
    belgian: 'Belga',
    belizean: 'Belizenho',
    beninese: 'Beninense',
    bhutanese: 'Butanês',
    bolivian: 'Boliviano',
    bosnian: 'Bósnio',
    brazilian: 'Brasileiro',
    british: 'Britânico',
    bruneian: 'Bruneiano',
    bulgarian: 'Búlgaro',
    burkinabe: 'Burquinês',
    burundian: 'Burundiano',
    cambodian: 'Cambojano',
    cameroonian: 'Camaronês',
    canadian: 'Canadense',
    cape_verdean: 'Cabo-verdiano',
    central_african: 'Centro-africano',
    chadian: 'Chadiano',
    chilean: 'Chileno',
    chinese: 'Chinês',
    colombian: 'Colombiano',
    comorian: 'Comorense',
    congolese: 'Congolês',
    costa_rican: 'Costarriquenho',
    croatian: 'Croata',
    cuban: 'Cubano',
    cypriot: 'Cipriota',
    czech: 'Tcheco',
    danish: 'Dinamarquês',
    djiboutian: 'Djiboutiano',
    dominican: 'Dominicano',
    dutch: 'Holandês',
    east_timorese: 'Timorense',
    ecuadorian: 'Equatoriano',
    egyptian: 'Egípcio',
    emirian: 'Emiradense',
    equatorial_guinean: 'Guinéu-equatoriano',
    eritrean: 'Eritreiano',
    estonian: 'Estoniano',
    ethiopian: 'Etíope',
    fijian: 'Fijiano',
    filipino: 'Filipino',
    finnish: 'Finlandês',
    french: 'Francês',
    gabonese: 'Gabonês',
    gambian: 'Gambiano',
    georgian: 'Georgiano',
    german: 'Alemão',
    ghanaian: 'Ganês',
    greek: 'Grego',
    grenadian: 'Granadino',
    guatemalan: 'Guatemalteco',
    guinea_bissauan: 'Guineense',
    guinean: 'Guineano',
    guyanese: 'Guianense',
    haitian: 'Haitiano',
    herzegovinian: 'Herzegovinense',
    honduran: 'Hondurenho',
    hungarian: 'Húngaro',
    icelander: 'Islandês',
    indian: 'Indiano',
    indonesian: 'Indonésio',
    iranian: 'Iraniano',
    iraqi: 'Iraquiano',
    irish: 'Irlandês',
    israeli: 'Israelense',
    italian: 'Italiano',
    ivorian: 'Costarriquenho',
    jamaican: 'Jamaicano',
    japanese: 'Japonês',
    jordanian: 'Jordaniano',
    kazakhstani: 'Cazaque',
    kenyan: 'Queniano',
    kittitian_and_nevisian: 'São-cristovense',
    kuwaiti: 'Kuwaitiano',
    kyrgyz: 'Quirguiz',
    laotian: 'Laosiano',
    latvian: 'Letão',
    lebanese: 'Libanês',
    liberian: 'Liberiano',
    libyan: 'Líbio',
    liechtensteiner: 'Liechtensteinense',
    lithuanian: 'Lituano',
    luxembourger: 'Luxemburguês',
    macedonian: 'Macedônio',
    malagasy: 'Malgaxe',
    malawian: 'Malauiense',
    malaysian: 'Malaio',
    maldivan: 'Maldiviano',
    malian: 'Maliano',
    maltese: 'Maltês',
    marshallese: 'Marshalense',
    mauritanian: 'Mauritano',
    mauritian: 'Mauriciano',
    mexican: 'Mexicano',
    micronesian: 'Micronésio',
    moldovan: 'Moldavo',
    monacan: 'Monegasco',
    mongolian: 'Mongol',
    montenegrin: 'Montenegrino',
    moroccan: 'Marroquino',
    mosotho: 'Lesotense',
    mozambican: 'Moçambicano',
    namibian: 'Namibiano',
    nauruan: 'Nauruano',
    nepalese: 'Nepalês',
    new_zealander: 'Neozelandês',
    ni_vanuatu: 'Vanuatuense',
    nicaraguan: 'Nicaraguense',
    nigerien: 'Nigerino',
    north_korean: 'Norte-coreano',
    norwegian: 'Norueguês',
    omani: 'Omanense',
    pakistani: 'Paquistanês',
    palauan: 'Palauano',
    panamanian: 'Panamenho',
    papua_new_guinean: 'Papuásio',
    paraguayan: 'Paraguaio',
    peruvian: 'Peruano',
    polish: 'Polonês',
    portuguese: 'Português',
    qatari: 'Qatariano',
    romanian: 'Romeno',
    russian: 'Russo',
    rwandan: 'Ruandês',
    saint_lucian: 'Santo-lucense',
    salvadoran: 'Salvadorenho',
    samoan: 'Samoano',
    san_marinese: 'São-marinhense',
    sao_tomean: 'São-tomense',
    saudi: 'Saudita',
    senegalese: 'Senegalês',
    serbian: 'Sérvio',
    seychellois: 'Seichelense',
    sierra_leonean: 'Serra-leonês',
    singaporean: 'Singapuriano',
    slovak: 'Eslovaco',
    slovenian: 'Esloveno',
    solomon_islander: 'Salomônico',
    somali: 'Somali',
    south_african: 'Sul-africano',
    south_korean: 'Sul-coreano',
    spanish: 'Espanhol',
    sri_lankan: 'Sri-lankês',
    sudanese: 'Sudanês',
    surinamer: 'Surinamês',
    swazi: 'Suazi',
    swedish: 'Sueco',
    swiss: 'Suíço',
    syrian: 'Sírio',
    taiwanese: 'Taiwanês',
    tajik: 'Tadjique',
    tanzanian: 'Tanzaniano',
    thai: 'Tailandês',
    togoan: 'Togolês',
    tongan: 'Tonganês',
    trinidadian_or_tobagonian: 'Trinitário ou Tobagoniano',
    tunisian: 'Tunisiano',
    turkish: 'Turco',
    tuvaluan: 'Tuvaluano',
    ugandan: 'Ugandense',
    ukrainian: 'Ucraniano',
    uruguayan: 'Uruguaio',
    uzbekistani: 'Uzbeque',
    venezuelan: 'Venezuelano',
    vietnamese: 'Vietnamita',
    yemenite: 'Iemenita',
    zambian: 'Zambiano',
    zimbabwean: 'Zimbabuano'
  },

  weekDays: {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  },

  gameFields: {
    competition: 'Competicão',
    level: 'Nível da Competição',
    gameLevel: 'Nível do Jogo',
    location: 'Local do Jogo',
    observed: 'Jogo Observado',
    date: 'Data do Jogo',
    minutes: 'Minutos jogados',
    startingPlayer: 'Saiu como titular',
    category: 'Categoria',  
    position: 'Posição no Jogo',
    stature: 'Estatura',
    foot: 'Pé dominante',
    gols: 'Gols',
    assistances: 'Assistências',
    yellowCards: 'Cartões Amarelos',
    redCards: 'Cartões Vermelhos',
    performance: 'Desempenho'
  },

  statureOptions: {
    alto: 'Alto',
    medio: 'Médio',
    baixo:  'Baixo',
  },

  categoryOptions: {
    profissional: 'Profissional',
    amador: 'Amador',
    sub23: 'Sub-23',
    sub22: 'Sub-22',
    sub21: 'Sub-21',
    sub20: 'Sub-20',
    sub19: 'Sub-19',
    sub18: 'Sub-18',
    sub17: 'Sub-17',
    sub16: 'Sub-16',
    sub15: 'Sub-15',
    sub14: 'Sub-14',
    sub13: 'Sub-13',
    sub12: 'Sub-12',
    sub11: 'Sub-11',
    sub10: 'Sub-10',
    sub9: 'Sub-9',
  },
  evaluationData: 'Dados da Avaliação',
  charts: {
    byPosition: 'Direcionamento Por Posição',
    forContract: 'Direcionados para Contratação',
    byYear: 'Por Ano de Nascimento',
    evaluations: 'Avaliações realizadas',
    athletes: 'Atletas Avaliados',
    byAnalist: 'Por Analista',
    filterByPosition: 'Por Posição',
    filterByPeriod: 'Por Período',
    filterByTargeting: 'Por Direcionamento',
    moreFilters: 'Mais Filtros',
    noDirection: 'Nenhum Direcionamento',
    noData: 'Tivemos um problema ao carregar os gráficos, tente novamente mais tarde.',
    byMomentClub: 'Por Clube no Momento da Avaliação',
    byActualClub: 'Por Clube Atual',
  },

  avaliationModel: {
    GOLEIROS: 'Goleiros',
    LATERAIS: 'Laterais',
    ZAGUEIROS: 'Zagueiros',
    VOLANTES: 'Volantes',
    'MEIAS-CENTRAIS': 'Meias Centrais',
    EXTREMOS: 'Extremos',
    'ATACANTES-REFERENCIA': 'Atante Referência',
    GERAL: 'Base Geral',
    'RELATORIO-DE-JOGO': 'Relatório de Jogo',
  },

  dashboard: {
    clubs: 'Clubes',
    numer_of_clubs: 'Nº de Clubes',
    agents: 'Agentes',
    numer_of_agents: 'Nº de Agentes',
    total_count_per_project: 'Por Projetos',
    total_count_per_license: 'Por Licenças',
    total_count_division: 'Por Série',
    total_count_agency: 'Por Categoria',    
    total_count_per_athlete: 'Por Atletas',
    total_count_agency_global: 'Global',
    total_count_agency_latam: 'Latam',
    total_count_agency_brazil: 'Brasil',
    total_count_division_1: 'Série A',
    total_count_division_2: 'Série B',
    total_count_division_3: 'Série C',
    total_count_division_4: 'Série D',
    total_count_division_null: 'Sem Série',
    total_count_foreign: 'Internacionais',
    transfer_market: 'Mercado de transferências',
    number_of_ads: 'Nº de Anúncios',
    transfer_market_filter: 'Incluir anúncios inativos',
    total_announcement_by_type: 'Por Tipo',    
    by_advertiser_count: 'Por Anunciante',
    by_negotiation_count: 'Por Negociações',
    total_announcement_athletes_team: 'Atleta do Elenco',
    total_announcement_desired_athletes: 'Atleta Desejado',
    total_announcement_athletes_agency: 'Atleta de Agência',
    market_analysis: 'Análise de Mercado',
    number_of_monitored_players: 'Nº de Atletas Monitorados',
    total_count_athletes_monitored_per_org: 'Por Monitoramento',
    total_count_evaluations_per_org: 'Por Avaliações',
    total_count_reports_per_org: 'Por Relatórios',
    chat: 'Chat',
    number_of_chats: 'Nº de Conversas',
    from_origin: 'Por Origem',
    from_organization: 'Por Organização',
    from_users: 'Por Usuário',
    total_count_agency_to_club: 'Negociações - Agente > Clube',
    total_count_club_to_club: 'Negociações - Clube > Clube',
    total_count_athlete_club_to_club: 'Atleta - Clube > Clube',
    total_count_athlete_agency_to_club: 'Atleta - Agente > Clube',
    total_count_athlete_agency_to_agency: 'Atleta - Agente > Agente',
    total_count_athlete_club_to_agency: 'Atleta - Clube > Agente',    
    total_count_team_club_to_agency: 'Clube - Clube > Agente',
    total_count_team_agency_to_club: 'Clube - Agente > Clube',
    total_count_team_agency_to_agency: 'Agente - Agente > Agente',
    total_count_team_club_to_club: 'Clube - Clube > Clube',
    last_30: 'Últimos 30 dias',
    last_60: 'Últimos 60 dias',
    last_90: 'Últimos 90 dias',
  },
  athlete_migration: {
    title: 'Migrar Dados de Atleta',
    step1Text: 'Agora você pode migrar os dados dos atletas inseridos pela sua organização para Atletas Footlink, ou seja, atletas que estão registrados na plataforma com seus dados oficiais de registro. Basta você inserir a URL do perfil do atleta no Footlink no campo abaixo, verificar se é o atleta correto e confirmar a migração dos dados inseridos por você.',
    step2TextBold: 'Confirma a migração dos dados inseridos pela sua organização para esse atleta?',
    step2Text: 'Essa ação não poderá ser revertida depois e o atleta original será excluído.',
    original: 'Atleta Original',
    footlink: 'Atleta Footlink',
    insertUrl: 'Insira a URL do perfil do atleta no Footlink',    
    success: 'Dados migrados com sucesso para este atleta.',
    warning: 'Este atleta está visível apenas para a sua organização.',
    warningButton: 'Migrar dados para Atleta Footlink',
    message1: 'Certifique-se que é o atleta correto:',
    message2: 'URL inválida ou não corresponde à rota esperada.',
    message3: 'O atleta inserido é o mesmo atleta que está sendo exibido.',
    message4: 'Não é possivel migrar para outro atleta customizado.'
  }
};
